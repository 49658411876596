/* EditableTable.css */

.custom-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.custom-table th,
.custom-table td {
    padding: 10px;
    text-align: left;
}

.custom-table thead {
    background-color: var(--topbar-color, #d21e1e);;
    color: #121111;
}

.custom-table th {
    font-weight: bold;
}

.custom-table tbody tr {
    border-bottom: 1px solid #f2f2f2;
}

.custom-table tbody tr:last-child {
    border-bottom: none;
}

.custom-table input {
    width: 100%;
}

.custom-table button {
    background-color: #3498db;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.custom-table button:hover {
    background-color: #2980b9;
}